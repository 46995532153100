import React from "react";

const Video = ({ src, title, locale, ...props }) => {
  const isYoutube = src.includes("youtube");

  return (
    <div className="video">
      <iframe
        src={
          isYoutube
            ? `${src}?autoplay=1`
            : `${src}?autoplay=1&dnt=1&color=f29eb4&texttrack=${locale}`
        }
        title={title}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        frameBorder="0"
        webkitallowfullscreen={true}
        mozallowfullscreen={true}
        allowFullScreen={true}
        width="560"
        height="349"
      />
    </div>
  );
};
export default Video;
