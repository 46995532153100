import React from "react";

const Map = ({ url, title, className }) => {
  return (
    <div className={`map ${className}`}>
      <iframe
        src={url}
        title={title}
        width="800px"
        height="800px"
        className="map__iframe"
      />
    </div>
  );
};
export default Map;
