import React, { useRef, useState } from "react";
import Slider from "react-slick";

import useTranslations from "../components/useTranslations";
import IconButton from "../components/iconButton";
import Arrow from "../images/svg/icon-arrow.svg";
import { LocaleContext } from "../components/layout";

export default function Carousel({ children }) {
  const { locale } = React.useContext(LocaleContext);
  const [currentSlide, updateCurrentSlide] = useState(0);

  const settings = {
    accessibility: true,
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    fade: true,
    rtl: locale === "ar",
  };

  const { displayWork } = useTranslations();

  const carousel = useRef();

  return (
    <div className="slider">
      <span className="slider__count">
        <span>{currentSlide + 1}</span>
        <span>
          <span className="work__count-divider">/</span>
          {children.length}
        </span>
      </span>
      {currentSlide > 0 && (
        <IconButton
          Icon={Arrow}
          label={displayWork}
          method={() => carousel.current.slickPrev()}
          className="slider__nav slider__prev"
        />
      )}
      <Slider
        {...settings}
        beforeChange={(current, next) => updateCurrentSlide(next)}
        ref={carousel}
        className="slider__container"
      >
        {children}
      </Slider>
      {currentSlide < children.length - 1 && (
        <IconButton
          Icon={Arrow}
          label={displayWork}
          method={() => carousel.current.slickNext()}
          className="slider__nav slider__next"
        />
      )}
    </div>
  );
}
