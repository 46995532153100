import React from "react";

import Carousel from "./carousel";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Gallery = ({ images, className }) => {
  return (
    <div className={`gallery ${className}`}>
      <Carousel>
        {images.map((item, index) => {
          const srcImg = getImage(item.image);
          return (
            <figure key={`gallery-img-${index}`}>
              <GatsbyImage
                image={srcImg}
                alt={item.alt}
                className="gallery__image"
                aria-describedby={`image-${index}`}
              />
              <figcaption id={`image-${index}`} className="visually-hidden">
                {item.alt}
              </figcaption>
            </figure>
          );
        })}
      </Carousel>
    </div>
  );
};
export default Gallery;
