import React from "react";

import Video from "./video";

const Audio = ({ url, title, className }) => {
  const isSoundcloud = url.includes("soundcloud");
  const isVimeo = url.includes("vimeo");

  let audioUrl;

  if (isSoundcloud) {
    audioUrl = `https://w.soundcloud.com/player/?url=${url}&color=%235a6030&auto_play=true&hide_related=true&show_comments=false&show_user=false&show_reposts=false&show_teaser=true&visual=true`;
  } else if (!isVimeo) {
    audioUrl = `https://www.mixcloud.com/widget/iframe/?light=1&autoplay=1&feed=%2F${url}%2F`;
  }

  return (
    <>
      {!isVimeo && (
        <div className={`audio ${className}`}>
          <iframe
            src={audioUrl}
            title={title}
            width="100%"
            height="400"
            frameBorder="0"
            allow="autoplay"
            className="audio__iframe"
          />
        </div>
      )}
      {isVimeo && <Video src={url} title={title} className="modal__content" />}
    </>
  );
};
export default Audio;
