import React, { useState, useRef, useEffect } from "react";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import Modal from "react-modal";

import Seo from "../components/seo";
import useTranslations from "../components/useTranslations";
import { LocaleContext } from "../components/layout";
import LocalizedLink from "../components/localizedLink";
import IconButton from "../components/iconButton";
import InfoBox from "../components/infoBox";
import Video from "../components/video";
import Audio from "../components/audio";
import Gallery from "../components/gallery";
import Map from "../components/map";

import Play from "../images/svg/icon-video.svg";
import Eye from "../images/svg/icon-eye.svg";
import MapIcon from "../images/svg/icon-map.svg";
import AudioIcon from "../images/svg/icon-audio.svg";
import Book from "../images/svg/icon-book.svg";
import Camera from "../images/svg/icon-camera.svg";
import Download from "../images/svg/icon-download.svg";
import Bubble from "../images/svg/icon-bubble.svg";
import Info from "../images/svg/icon-info.svg";
import IconLink from "../images/svg/icon-link.svg";
import Arrow from "../images/svg/icon-arrow.svg";
import Close from "../images/svg/icon-close.svg";
import Cross from "../images/svg/icon-cross.svg";

const isBrowser = typeof window !== "undefined";

export default function Work({ data, isHomepage }) {
  const [isInfoOpen, showInfo] = useState(false);
  const [openHashtag, showHashtag] = useState(null);
  const [isLinksOpen, openLinks] = useState(false);
  const [openedModal, openModal] = useState(null);

  const { locale, url, isEinfacheSprache } = React.useContext(LocaleContext);
  const info = useRef();

  const {
    displayHashtagText,
    displayWork,
    showDescription,
    showLinks,
    showNextWork,
    showPreviousWork,
    close,
    video,
    audio,
    gallery,
    photo,
    map,
    pdf,
    text,
    mix,
    playVideo,
    playAudio,
    showImages,
    showMap,
    downloadPdf,
    readText,
  } = useTranslations();

  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", closeOnClickOutside);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", closeOnClickOutside);
    };
  }, []);

  const work = data.markdownRemark;
  const image = getImage(work.frontmatter.cover);
  const allWorks = data.allMarkdownRemark.edges;
  const workCount = data.allMarkdownRemark.totalCount;
  const allHashtags = hashtagsByLocale();

  const nextWork = isHomepage
    ? allWorks.find((work) => work.node.frontmatter.id === 2)
    : allWorks.find(
        (work) =>
          work.node.frontmatter.id === data.markdownRemark.frontmatter.id + 1
      );

  const prevWork = isHomepage
    ? null
    : allWorks.find(
        (work) =>
          work.node.frontmatter.id === data.markdownRemark.frontmatter.id - 1
      );

  const hashtags = returnRelatedHashtags(allHashtags);

  const pageLinks = returnRelatedPages();
  const externalLinks = returnRelatedExternalLinks();

  const description = allHashtags.find(
    (hashtag) => hashtag.id === data.markdownRemark.frontmatter.description
  ).text;

  const metaDescription = description.replace(
    /<([^( |>)]+)([^>]*)>.*?<\/\1>|<.*?\/s>/g,
    ""
  );

  function displayIcon(type) {
    switch (type) {
      case "video":
        return Play;
      case "ad":
        return Eye;
      case "map":
        return MapIcon;
      case "book":
        return Book;
      case "gallery":
        return Camera;
      case "audio":
        return AudioIcon;
      case "download":
        return Download;
      case "bubble":
        return Bubble;
      case "text":
        return Book;
      default:
        return Play;
    }
  }

  function displayLabel(type) {
    switch (type) {
      case "video":
        return playVideo;
      case "ad":
        return "Hörfilmfassung abspielen";
      case "map":
        return showMap;
      case "book":
        return showImages;
      case "gallery":
        return showImages;
      case "audio":
        return playAudio;
      case "download":
        return downloadPdf;
      case "bubble":
        return playVideo;
      case "text":
        return readText;
      default:
        return displayWork;
    }
  }

  function displayCategory() {
    const type = work.frontmatter.type;

    const localizedCategories = type
      .map((item) => {
        switch (item) {
          case "video":
            return video;
          case "ad":
            return "Hörfilmfassung";
          case "bubble":
            return video;
          case "map":
            return map;
          case "audio":
            return audio;
          case "gallery":
            return gallery;
          case "book":
            return gallery;
          case "photo":
            return photo;
          case "download":
            return pdf;
          case "text":
            return text;
          case "mix":
            return mix;
          default:
            return Play;
        }
      })
      .filter((item, i, arr) => {
        return arr.indexOf(item) === i;
      });

    return localizedCategories.join(" | ");
  }

  function closeOnClickOutside(e) {
    if (info.current.contains(e.target)) {
      return;
    }

    showInfo(false);
  }

  function hashtagsByLocale() {
    let hashtags;

    if (locale === "de") {
      if (isEinfacheSprache) {
        hashtags = data.allHashtags.edges.filter(function (item) {
          return item.node.isSimplified;
        })[0].node.hashtags;
      } else {
        hashtags = data.allHashtags.edges.filter(function (item) {
          return !item.node.isSimplified;
        })[0].node.hashtags;
      }
    } else {
      hashtags = data.allHashtags.edges[0].node.hashtags;
    }

    return hashtags;
  }

  function returnRelatedHashtags(hashtags) {
    if (work.frontmatter.hashtags) {
      return hashtags.filter(function (item) {
        return work.frontmatter.hashtags.indexOf(item.id) !== -1;
      }, work.frontmatter.hashtags);
    } else {
      return null;
    }
  }

  function returnRelatedPages() {
    return work.frontmatter.link
      ? work.frontmatter.link.map((item) => {
          if (typeof item === "string" && item.indexOf("http") !== -1) {
            return {
              label: item,
              url: item,
            };
          } else {
            const page = allWorks.find(
              (element) => element.node.frontmatter.id === item
            );

            return {
              label: page.node.frontmatter.title,
              url: page.node.fields.baseSlug,
            };
          }
        })
      : null;
  }

  function returnRelatedExternalLinks() {
    return work.frontmatter.external
      ? work.frontmatter.external.map((item) => ({
          label: item,
          url: item,
        }))
      : [];
  }

  const hashtagsInJson = isBrowser
    ? document.querySelectorAll(".js-display-hashtag")
    : [];

  for (let i = 0; i < hashtagsInJson.length; i++) {
    hashtagsInJson[i].addEventListener("click", function (e) {
      const currentHashtag = e.srcElement.innerHTML;
      const hashtagToDisplay = allHashtags.find(
        (item) => item.shortcut === currentHashtag
      );

      if (hashtagToDisplay) {
        showHashtag({
          title: hashtagToDisplay.hashtag,
          text: hashtagToDisplay.text,
        });
      }
    });
  }

  return (
    <>
      {!isHomepage && (
        <Seo
          url={url}
          lang={locale}
          title={work.frontmatter.title}
          description={metaDescription}
          bodyClass="work-page"
          featuredImg={`${data.site.siteMetadata.siteUrl}${image.images.fallback.src}`}
        />
      )}
      <div className="work container">
        <h1
          className="work__title main-title"
          lang={locale}
          dangerouslySetInnerHTML={{ __html: work.frontmatter.title }}
        />
        <div className="work__authors work__authors--mobile work__box">
          {work.frontmatter.author}
        </div>
        <div className="work__wrapper">
          <div className="work__content">
            <div className="work__authors work__authors--desktop work__box">
              {work.frontmatter.author}
            </div>
            <BgImage
              image={image}
              className="work__container aspect-ratio--16-9"
            >
              <div className="background-overlay"></div>

              <div className="work__buttons">
                {work.frontmatter.type[0] !== "download" &&
                  work.frontmatter.type.map((item, i) => (
                    <IconButton
                      key={`work-button-${i}`}
                      Icon={displayIcon(item)}
                      label={displayLabel(item)}
                      method={() => openModal({ item, i })}
                      numbering={item === "bubble" ? i + 1 : null}
                      className="work__open"
                    ></IconButton>
                  ))}

                {work.frontmatter.type[0] === "download" && (
                  <a
                    href={work.frontmatter.download}
                    title="PDF"
                    target="__blank"
                  >
                    <IconButton
                      Icon={displayIcon("download")}
                      label={downloadPdf}
                      className="work__open"
                    ></IconButton>
                  </a>
                )}
              </div>

              <IconButton
                Icon={isInfoOpen ? Close : Info}
                label={isInfoOpen ? close : showDescription}
                method={() => showInfo(!isInfoOpen)}
                className="work__info"
              />

              <div
                ref={info}
                className={`work__description ${
                  isInfoOpen ? "work__description--visible" : ""
                }`}
              >
                <div
                  className="work__description-content"
                  dangerouslySetInnerHTML={{ __html: description }}
                />
                <IconButton
                  Icon={Close}
                  label={close}
                  method={() => showInfo(!isInfoOpen)}
                  className="work__description-close"
                />
              </div>

              {pageLinks && (
                <div className="work__links">
                  <InfoBox
                    isLinks={true}
                    innerRef={info}
                    method={() => openLinks(false)}
                    isVisible={isLinksOpen}
                  >
                    <ul className="work__links-list">
                      {pageLinks.map((link, i) => (
                        <li key={`link-${i}`}>
                          <LocalizedLink to={link.url}>
                            {link.label}
                          </LocalizedLink>
                        </li>
                      ))}

                      {externalLinks &&
                        externalLinks.map((link, i) => (
                          <li key={`link-${i}`}>
                            <a
                              href={link.url}
                              target="_blank"
                              rel="noopener noreferrer nofollow"
                            >
                              {link.label}
                            </a>
                          </li>
                        ))}
                    </ul>
                  </InfoBox>
                  <IconButton
                    isActive={isLinksOpen}
                    Icon={IconLink}
                    label={showLinks}
                    method={() => openLinks(true)}
                    className="work__link"
                  />
                </div>
              )}
            </BgImage>

            <div className="work__pages">
              <div className="work__count work__count--mobile">
                <span>{work.frontmatter.id}</span>
                <span>
                  <span className="work__count-divider">/</span>
                  {workCount}
                </span>
              </div>

              <div className="work__navigation">
                {prevWork && (
                  <LocalizedLink
                    className="work__navigation-arrow work__navigation-arrow--prev"
                    to={`${prevWork.node.fields.baseSlug}`}
                  >
                    <IconButton
                      Icon={Arrow}
                      label={showPreviousWork}
                      className="work__navigation-arrow-svg"
                    />
                  </LocalizedLink>
                )}

                {nextWork && (
                  <LocalizedLink
                    className="work__navigation-arrow work__navigation-arrow--next"
                    to={`${nextWork.node.fields.baseSlug}`}
                  >
                    <IconButton
                      Icon={Arrow}
                      label={showNextWork}
                      className="work__navigation-arrow-svg"
                    />
                  </LocalizedLink>
                )}
              </div>
            </div>
          </div>

          <InfoBox
            innerRef={info}
            title={openHashtag && openHashtag.title}
            text={openHashtag && openHashtag.text}
            method={() => showHashtag(null)}
            isVisible={openHashtag}
          />

          <div className="work__meta">
            {(work.frontmatter.year || work.frontmatter.length) && (
              <div className="work__boxes">
                <div className="work__box work__category">
                  {displayCategory()}
                </div>
                <div className="work__box work__year">
                  {work.frontmatter.year}
                </div>
                <div className="work__box work__length">
                  {work.frontmatter.length}
                </div>
              </div>
            )}

            {!work.frontmatter.year && !work.frontmatter.length && (
              <div className="work__boxes">
                <div className="work__box work__category">
                  {displayCategory()}
                </div>
              </div>
            )}

            <ul className="work__hashtags work__hashtags--desktop">
              {hashtags &&
                hashtags.map((item, i) => (
                  <li key={`hashtag-${i}`}>
                    <button
                      className="work__hashtag"
                      title={displayHashtagText}
                      onClick={() =>
                        showHashtag({ title: item.hashtag, text: item.text })
                      }
                    >
                      {item.hashtag}
                    </button>
                  </li>
                ))}
            </ul>

            <div className="work__count work__count--desktop">
              <span>{work.frontmatter.id}</span>
              <span>
                <span className="work__count-divider">/</span>
                {workCount}
              </span>
            </div>
          </div>

          <ul className="work__hashtags work__hashtags--mobile">
            {hashtags &&
              hashtags.map((item, i) => (
                <li key={`hashtag-${i}`}>
                  <button
                    className="work__hashtag"
                    onClick={() =>
                      showHashtag({ title: item.hashtag, text: item.text })
                    }
                  >
                    {item.hashtag}
                  </button>
                </li>
              ))}
          </ul>
        </div>

        <Modal
          isOpen={openedModal ? true : false}
          contentLabel={work.frontmatter.title}
          onRequestClose={() => openModal(false)}
          className="modal"
          overlayClassName="overlay"
          closeTimeoutMS={500}
          className={`modal 
            modal--${openedModal?.item} 
            modal--${work.fields.baseSlug.replace(/^\/+/, "")}
        `}
        >
          <IconButton
            Icon={Cross}
            label={close}
            method={() => openModal(false)}
            className="modal__close"
          />

          {openedModal &&
            (openedModal.item === "video" || openedModal.item === "bubble") && (
              <Video
                src={
                  Array.isArray(work.frontmatter.video)
                    ? work.frontmatter.video[openedModal.i]
                    : work.frontmatter.video
                }
                title={work.frontmatter.title}
                locale={locale}
                className="modal__content"
              />
            )}

          {openedModal && openedModal.item === "ad" && (
            <Video
              src={
                Array.isArray(work.frontmatter.ad)
                  ? work.frontmatter.ad[0]
                  : work.frontmatter.ad
              }
              title={work.frontmatter.title}
              locale={locale}
              className="modal__content"
            />
          )}

          {openedModal && openedModal.item === "audio" && (
            <Audio
              url={
                Array.isArray(work.frontmatter.audio)
                  ? work.frontmatter.audio[openedModal.i]
                  : work.frontmatter.audio
              }
              title={work.frontmatter.title}
              className="modal__content"
            />
          )}

          {openedModal &&
            (openedModal.item === "gallery" || openedModal.item === "book") && (
              <Gallery
                images={work.frontmatter.gallery}
                className="modal__content"
              />
            )}

          {openedModal && openedModal.item === "map" && (
            <Map
              url={work.frontmatter.map}
              title={work.frontmatter.title}
              className="modal__content"
            />
          )}

          {openedModal && openedModal.item === "text" && (
            <div
              className="modal__content"
              dangerouslySetInnerHTML={{ __html: work.html }}
            />
          )}
        </Modal>
      </div>
    </>
  );
}
export const query = graphql`
  query ($locale: String!, $title: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    markdownRemark(
      frontmatter: { title: { eq: $title } }
      fields: { locale: { eq: $locale } }
    ) {
      html
      fields {
        baseSlug
      }
      frontmatter {
        id
        title
        description
        author
        type
        video
        map
        audio
        download
        ad
        year
        length
        hashtags
        link
        external
        gallery {
          image {
            childImageSharp {
              gatsbyImageData(
                width: 600
                placeholder: TRACED_SVG
                formats: [AUTO, WEBP, AVIF]
                quality: 100
              )
            }
          }
          alt
        }
        cover {
          childImageSharp {
            gatsbyImageData(
              width: 1000
              placeholder: TRACED_SVG
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
    allMarkdownRemark(
      filter: {
        fields: { locale: { eq: $locale } }
        frontmatter: { author: { ne: null } }
      }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            id
            title
          }
          fields {
            baseSlug
          }
        }
      }
    }
    allHashtags(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          locale
          isSimplified
          hashtags {
            hashtag
            shortcut
            text
            id
          }
        }
      }
    }
  }
`;
