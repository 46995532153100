import React from "react";

import useTranslations from "./useTranslations";
import IconButton from "./iconButton";
import Cross from "../images/svg/icon-cross.svg";

export default function Infobox({
  children,
  title,
  text,
  isVisible,
  method,
  isLinks,
}) {
  const { close } = useTranslations();

  return (
    <div
      className={`info-box ${isVisible ? "info-box--visible" : ""} ${
        isLinks ? "info-box--links" : ""
      }`}
    >
      <div className="info-box__meta">
        {title && <h2 className="info-box__title">{title}</h2>}
        <IconButton
          Icon={Cross}
          label={close}
          className="info-box__close"
          method={method}
        />
      </div>
      <div className="info-box__container">
        <div dangerouslySetInnerHTML={{ __html: text }} />
        {children}
      </div>
    </div>
  );
}
